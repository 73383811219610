import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'
import Config from '../../config'

export default function LockdownMerchantPromoOttawa () {
  const shareTitle = '$25,000 for local businesses during lockdown'
  const articleLink = 'https://ottawa.ctvnews.ca/video?clipId=2113822'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>$25,000 for Ottawa businesses during lockdown</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>January 7, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/ctv-press-25000.jpg" alt="CTV Preview" />
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel='noreferrer'>CTV Ottawa</a></div>
                <p>The first 100 Ottawa businessess who <a href={`${Config.MERCHANT_PORTAL_URL}/signup`}>sign up</a> as new Trexity merchants before January 21, 2021 will receive $250 in delivery credit to be used by February 12, 2021.</p>
                <a href={articleLink} target='_blank' rel='noreferrer'>Watch on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/love-local-ottawa-trexity" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Love Local Ottawa interviews Trexity on CTV Morning News</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
